<template>
  <v-container>
    <v-img
      :src="require('@/assets/reservation_bg.png')"
      contain
      style="position: absolute; top: 0; right: 0"
    ></v-img>
    <back-toolbar title="detail.reserveTable"></back-toolbar>
    <v-row>
      <v-col cols="12">
        <v-card style="border-radius: 20px !important" class="mt-8">
          <v-card-actions class="py-1">
            <div
              @click="
                (progress = 30),
                  (calendarActive = true),
                  (peopleActive = false),
                  (timeActive = false)
              "
              style="height: 24px; margin: 0 auto"
            >
              <v-btn icon v-if="calendarActive" width="24" height="24">
                <img
                  class="color-filter"
                  :src="
                    calendarActive
                      ? require('@/assets/reservation/calendar_active.svg')
                      : require('@/assets/reservation/calendar.svg')
                  "
                />
              </v-btn>
              <v-btn
                v-else
                plain
                text
                class="Heading-Dark-H5-16-Center"
                style="height: 24px"
              >
                {{ croppedDate }}
              </v-btn>
            </div>
            <v-divider vertical></v-divider>
            <div
              @click="
                (progress = 70),
                  (calendarActive = false),
                  (timeActive = true),
                  (peopleActive = false)
              "
              style="height: 24px; margin: 0 auto"
            >
              <v-btn icon v-if="!peopleActive" width="24" height="24">
                <img
                  class="color-filter"
                  width="24"
                  height="24"
                  :src="
                    timeActive
                      ? require('@/assets/reservation/time_active.svg')
                      : require('@/assets/reservation/time.svg')
                  "
                  style="margin: 0 auto"
                />
              </v-btn>
              <v-btn
                v-else
                plain
                text
                class="Heading-Dark-H5-16-Center"
                style="height: 24px"
              >
                {{ selectedTime.time }}
              </v-btn>
            </div>
            <v-divider vertical></v-divider>
            <div
              @click="
                (progress = 100),
                  (peopleActive = true),
                  (calendarActive = false),
                  (timeActive = false)
              "
              style="height: 24px; margin: 0 auto"
            >
              <v-btn
                icon
                width="24"
                height="24"
                color="black"
                v-if="!selectedGuests || !peopleActive"
                style="margin: 0 auto"
              >
                <img
                  class="color-filter"
                  :src="
                    peopleActive
                      ? require('@/assets/reservation/people_active.svg')
                      : require('@/assets/reservation/people.svg')
                  "
                />
              </v-btn>
              <v-btn
                v-else
                plain
                text
                class="Heading-Dark-H5-16-Center"
                style="height: 24px"
              >
                {{ selectedGuests.number }}
                {{
                  selectedGuests.number === 1
                    ? $t("reservation.guest")
                    : $t("reservation.guests")
                }}
              </v-btn>
            </div>
          </v-card-actions>
          <v-card-actions class="px-10 pt-0"
            ><v-progress-linear
              background-color="onbackground"
              color="primary"
              rounded
              :value="progress"
            ></v-progress-linear
          ></v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="calendarActive">
      <v-col cols="12">
        <v-card class="mt-12" elevation="0">
          <date-picker
            v-model="date"
            mode="date"
            is-expanded
            style="border: none"
            color="blue"
            :min-date="localDate"
            :disabled-dates="disabledDates"
          ></date-picker>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="timeActive">
      <v-col>
        <v-card elevation="0">
          <div
            v-if="breakfast.length"
            class="Heading-Dark-H4-18-Left-Align ml-1 mt-3"
          >
            {{ $t("reservation.breakfast") }}
          </div>
          <v-divider v-if="breakfast.length"></v-divider>
          <v-btn
            v-for="time in breakfast"
            :key="time.time"
            :ripple="false"
            style="border-radius: 12px; width: 22%"
            outlined
            class="px-5 mx-1 mt-2 Heading-Dark-H6-14-Center"
            :class="time.selected ? 'gradient white--text' : ''"
            @click="select(time)"
            >{{ time.time }}</v-btn
          >
          <div
            v-if="lunch.length"
            class="Heading-Dark-H4-18-Left-Align ml-1 mt-3"
          >
            {{ $t("reservation.lunch") }}
          </div>
          <v-divider v-if="lunch.length"></v-divider>
          <v-btn
            v-for="time in lunch"
            :key="time.time"
            :ripple="false"
            style="border-radius: 12px; width: 22%"
            outlined
            class="px-5 mx-1 mt-2 Heading-Dark-H6-14-Center"
            :class="time.selected ? 'gradient white--text' : ''"
            @click="select(time)"
            >{{ time.time }}</v-btn
          >
          <div
            v-if="dinner.length"
            class="Heading-Dark-H4-18-Left-Align ml-1 mt-3"
          >
            {{ $t("reservation.dinner") }}
          </div>
          <v-divider v-if="dinner.length"></v-divider>
          <v-btn
            v-for="time in dinner"
            :key="time.time"
            :ripple="false"
            style="border-radius: 12px; width: 22%"
            outlined
            class="px-5 mx-1 mt-2 Heading-Dark-H6-14-Center"
            :class="time.selected ? 'gradient white--text' : ''"
            @click="select(time)"
            >{{ time.time }}</v-btn
          >
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="peopleActive">
      <v-col>
        <v-card elevation="0">
          <div class="Heading-Dark-H4-18-Left-Align ml-1 mt-3">
            {{ $t("reservation.guestsNumber") }}
          </div>
          <v-divider></v-divider>
          <v-btn
            v-for="guest in guests"
            :key="guest.number"
            :ripple="false"
            style="border-radius: 12px; width: 22%"
            outlined
            :class="guest.selected ? 'gradient white--text' : ''"
            @click="select(guest)"
            class="px-5 mx-1 mt-2 Heading-Dark-H6-14-Center"
            >{{ guest.number }}</v-btn
          >
        </v-card>
      </v-col>
    </v-row>
    <v-card
      style="position: absolute; bottom: 60px; width: 100%; left: 0"
      elevation="0"
      v-if="peopleActive"
    >
      <v-card-actions>
        <v-btn
          width="100%"
          height="48"
          depressed
          color="primary"
          :loading="$store.state.reserveout.status === 'fetching'"
          class="gradient mt-5 Heading-White-H4-18-Center"
          @click="bookNow"
          :disabled="!selectedGuests"
          >{{ $t("reservation.book") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="errorDialog"
      max-width="325"
      :timeout="-1"
      overlay-color="backgroundoverlay"
      overlay-opacity="1"
    >
      <v-card style="border-radius: 20px !important" flat class="pa-0">
        <v-toolbar flat dense>
          <v-spacer></v-spacer>
          <v-btn icon @click="errorDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="Text-Dark-Text-1---18-Center mt-4">
          {{ errorMessage }}
        </v-card-text>
        <v-container class="text-center">
          <v-row>
            <v-col cols="12">
              <v-btn
                @click="errorDialog = false"
                min-height="48"
                min-width="150"
                class="gradient white--text Text-Dark-Text-1---18-Center"
                >{{ $t("profile.ok") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import BackToolbar from "@/components/app/common/BackToolbar";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import Vue from "vue";
import api from "@/api";
export default {
  data: () => ({
    progress: 30,
    active: false,
    calendarActive: true,
    timeActive: false,
    peopleActive: false,
    // needed to autoselect and highlight the current date
    date: "",
    hours: [],
    halfHours: ["00", "30"],
    guests: [
      {
        number: 1,
        selected: false,
      },
      {
        number: 2,
        selected: false,
      },
      {
        number: 3,
        selected: false,
      },
      {
        number: 4,
        selected: false,
      },
      {
        number: 5,
        selected: false,
      },
      {
        number: 6,
        selected: false,
      },
      {
        number: 7,
        selected: false,
      },
      {
        number: 8,
        selected: false,
      },
      {
        number: 9,
        selected: false,
      },
      {
        number: 10,
        selected: false,
      },
    ],
  }),
  components: {
    BackToolbar,
    DatePicker,
  },
  computed: {
    errorDialog: {
      get() {
        return this.$store.state.reserveout.status === "failure";
      },
      set(val) {
        this.$store.commit("reserveout/RESET_ERROR");
      },
    },
    errorMessage() {
      // This forces the error message to be updated when the status changes
      this.$store.state.reserveout.status;

      // Show simple error message if no error message was received.
      if (!this.$store.state.reserveout.error) {
        return Vue.i18n.translate("reservation.noOptionsFound", {
          date: moment(this.date).format("YYYY-MM-DD"),
          time: this.selectedTime ? this.selectedTime.time : "",
          covers: this.selectedGuests ? this.selectedGuests.number : "",
          header: this.$route.params.header,
        });
      } else {
        return this.$store.state.reserveout.error;
      }
    },
    breakfast() {
      return this.hours.filter(
        (time) =>
          time.time > "07:00" &&
          time.time < "11:30" &&
          this.allowedTimes.findIndex((o) => {
            return o === time.time;
          }) > -1
      );
    },
    lunch() {
      return this.hours.filter(
        (time) =>
          time.time > "11:00" &&
          time.time < "17:00" &&
          this.allowedTimes.findIndex((o) => {
            return o === time.time;
          }) > -1
      );
    },
    dinner() {
      return this.hours.filter(
        (time) =>
          time.time > "16:30" &&
          time.time < "23:00" &&
          this.allowedTimes.findIndex((o) => {
            return o === time.time;
          }) > -1
      );
    },
    croppedDate() {
      return (
        this.date.toDateString().substring(0, 3) +
        " " +
        this.date.toString().substring(7, 10)
      );
    },
    selectedTime() {
      const obj = this.hours.filter((el) => el.selected);
      return obj[0];
    },
    selectedGuests() {
      const obj = this.guests.filter((el) => el.selected);
      return obj[0];
    },
    profile() {
      return this.$store.state.profile.data;
    },
    dateConfirmed() {
      return (
        this.date.toLocaleString("default", {
          weekday: "short",
          day: "numeric",
        }) +
        ", " +
        this.date.toLocaleString("default", { month: "short", year: "numeric" })
      );
    },
    bookedWeekday() {
      const weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";
      if (this.date) {
        return weekday[this.date.getDay()];
      } else {
        return null;
      }
    },
    disabledDates() {
      const weekdays = new Array(7);
      weekdays[1] = "Sunday";
      weekdays[2] = "Monday";
      weekdays[3] = "Tuesday";
      weekdays[4] = "Wednesday";
      weekdays[5] = "Thursday";
      weekdays[6] = "Friday";
      weekdays[7] = "Saturday";
      let disabledWeekdays = [];
      if (!this.availableDates && this.recommendation?.jsonOperatingHours) {
        let operatingHours = JSON.parse(this.recommendation.jsonOperatingHours);
        for (let i = 0; i < weekdays.length; i++) {
          let weekday = weekdays[i];
          if (!operatingHours[weekday]) {
            disabledWeekdays.push(i);
          }
        }
        return { weekdays: disabledWeekdays };
      }
      return null;
    },
    localTime() {
      let minutes = this.localDate.getMinutes();
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      return `${this.localDate.getHours()}:${minutes}`;
    },
    localDate() {
      Date.prototype.addHours = function (h) {
        this.setTime(this.getTime() + h * 60 * 60 * 1000);
        return this;
      };
      let userOffset = Number(new Date().getTimezoneOffset()) / -60;
      let recommendationOffset = api.getTimeOffset(this.recommendation.city);
      let difference = Number(recommendationOffset) - userOffset;
      return new Date().addHours(difference);
    },
    allowedTimes() {
      if (!this.bookedWeekday) {
        return [];
      }
      let allowedTimes = [];
      let operatingHours = JSON.parse(this.recommendation.jsonOperatingHours);
      operatingHours = operatingHours[this.bookedWeekday];
      for (let i = 0; i < operatingHours.length; i++) {
        let from = operatingHours[i].from;
        let to = operatingHours[i].to;
        let hour, minute;
        if (
          from.split(" ")[1] === "pm" &&
          from.split(" ")[0].split(":")[0] !== "12"
        ) {
          hour = Number(from.split(" ")[0].split(":")[0]) + 12;
          from = hour + ":" + from.split(" ")[0].split(":")[1];
        } else {
          from = from.split(" ")[0];
        }
        if (
          to.split(" ")[1] === "pm" &&
          to.split(" ")[0].split(":")[0] !== "12"
        ) {
          hour = Number(to.split(" ")[0].split(":")[0]) + 12;
          to = hour + ":" + to.split(" ")[0].split(":")[1];
        } else {
          to = to.split(" ")[0];
        }
        if (to < from) {
          hour = Number(to.split(":")[0]) + 24;
          to = hour + ":" + to.split(":")[1];
        }
        let counter = 0;
        for (let j = from; j <= to && counter < 100; counter++) {
          hour = Number(j.split(":")[0]);
          minute = Number(j.split(":")[1]);
          let hourString = (hour < 10 ? "0" : "") + String(hour);
          let minuteString = (minute < 10 ? "0" : "") + String(minute);
          allowedTimes.push(hourString + ":" + minuteString);
          minute += 30;
          if (minute >= 60) {
            minute -= 60;
            hour += 1;
          }
          hourString = (hour < 10 ? "0" : "") + hour;
          minuteString = (minute < 10 ? "0" : "") + minute;
          j = hourString + ":" + minuteString;
        }
      }
      if (
        allowedTimes &&
        this.date.toDateString() === new Date().toDateString()
      ) {
        let todaysAllowedTimes = [];
        allowedTimes.forEach((time) => {
          console.log(time, this.localTime, time > this.localTime);
          if (time > this.localTime) {
            todaysAllowedTimes.push(time);
          }
        });
        return todaysAllowedTimes;
      }
      return allowedTimes;
    },
    recommendation() {
      let recommendation = this.$store.state.recommendations.details;
      return recommendation;
    },
  },
  methods: {
    getTimes() {
      for (var i = 0; i < 24; i++) {
        for (var j = 0; j < 2; j++) {
          var time = { time: i + ":" + this.halfHours[j], selected: false };
          if (i < 10) {
            time = { time: "0" + time.time, selected: false };
          }
          this.hours.push(time);
        }
      }
    },
    select(item) {
      if (this.peopleActive) {
        this.guests.forEach((el) => {
          el.selected = false;
        });
      } else if (this.timeActive) {
        this.hours.forEach((el) => {
          el.selected = false;
        });
        this.progress = 100;
        this.peopleActive = true;
        this.calendarActive = false;
        this.timeActive = false;
      }
      item.selected = !item.selected;
    },
    bookNow() {
      this.$store.dispatch("reserveout/checkAvailability", {
        placeId: this.$store.state.recommendations.details.wowId,
        covers: this.selectedGuests.number,
        date: moment(this.date).format("YYYY-MM-DD"),
        time: this.selectedTime.time,
        header: this.$store.state.recommendations.details.header,
        countryId: this.$store.state.recommendations.details.countryId,
      });
    },
  },
  mounted() {
    // Prevent access to reservation without login
    if (!this.$store.state.session.user && !this.$store.state.isMiKY) {
      if (this.$router.from.name !== "login") {
        this.$router.safePush({ name: "login" });
      } else {
        this.$router.back();
      }
    }
    this.$store.commit("reserveout/RESET_ERROR");
    this.getTimes();
  },
  watch: {
    date(val) {
      if (val) {
        this.progress = 70;
        this.calendarActive = false;
        this.timeActive = true;
        this.peopleActive = false;
      }
    },
  },
};
</script>
<style scoped>
hr.v-divider--vertical {
  width: 2px;
  border: 1px solid #c1c7ca;
}
/deep/ .vc-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 8px;
  margin-bottom: 12px;
}
/deep/ .vc-day-box-center-center[data-v-005dafc8] {
  height: 50px;
}
/deep/ .v-btn--outlined {
  border: 2px solid #9da6aa;
}
</style>
